import { getRequestHeaders } from "~/utils/api";

type payloadLogin_t = {
  email: string;
  password: string;
};

type LoginData_T = {
  id: string;
  name: string;
  email: string;
  is_super_admin: boolean;
};

type LoginResponse_t = {
  token: string;
  data: LoginData_T;
};

const {
  profile,
  posPermission,
  managementPermission,
  isOneManagementPermissionActive,
  isOnePosPermissionActive,
  branch,
  terminalId,
} = useInitialLocalStore();

export const useLogin = () => {
  // Login
  const login = (payload: payloadLogin_t) => {
    const now = new Date();
    now.setDate(now.getDate() + 7);
    const authToken = useCookie("auth.token", { expires: now });
    authToken.value = null;
    return $fetch("/management-api/auth/login", {
      method: "post",
      headers: getRequestHeaders(false),
      body: payload,
    })
      .then((response) => {
        const { token: tokenAuth, data } = response as LoginResponse_t;
        authToken.value = tokenAuth;
        profile.value = data;
        return new Promise((resolve) => {
          resolve(response);
        });
      })
      .catch((error) => {
        authToken.value = null;
        profile.value = null;
        return new Promise((_resolve, reject) => {
          reject(error);
        });
      });
  };

  // Logout
  const logout = () => {
    const pinToken = useCookie("auth.pinToken");
    const cookies = useCookie("auth.token");
    profile.value = null;
    posPermission.value = null;
    managementPermission.value = null;
    isOneManagementPermissionActive.value = null;
    isOnePosPermissionActive.value = null;
    branch.value = null;
    cookies.value = null;
    if (pinToken.value) {
      window.location.href = `/pin-login?token=${pinToken.value}`;
    } else if (terminalId.value) {
      window.location.href = "/?terminal=1";
      pinToken.value = null;
    } else {
      terminalId.value = null;
      pinToken.value = null;
      window.location.href = "/";
    }
  };

  return {
    login,
    logout,
  };
};
